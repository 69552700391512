import { memo, FC, useState, useEffect } from 'react';
import { Card, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { useToast } from '@/hooks';
import { PayInfo } from '@/common/interface'
import { GetOrder } from '@/services';
// import { useTranslation } from 'react-i18next';
// import wxZk20 from '../../assets/images/qrcodes/wx-zk-20.jpg';

interface IProps {
  showCallback: Function;
  info: PayInfo;
  username: string;
}

// const payImages = {
//   "wx-zk-20": wxZk20
// }

const Index: FC<IProps> = ({ showCallback, info, username }) => {
  const [remain, setRemain] = useState("");
  const [count, setCount] = useState(0);
  const show: boolean = true;
  const toast = useToast();
  const navigate = useNavigate();
  const paymentText = info.payment_method === "ali" ? "支付宝支付" : "微信支付";
  const handleCloseOrder = () => {
    showCallback(false)
  };
  // const img = payImages[`${info.method}-${info.payee}-${info.amount.toFixed(0)}`]
  // const imgPos = info.discount === 0 ? 0 : info.discount * 100 * 256

  // const qrcodeStyle = {
  //   backgroundImage: `url(${img})`,
  //   width: 256,
  //   height: 302,
  //   backgroundPosition: `${imgPos}px 0`
  // }

  // const { t } = useTranslation();
  // console.log(amount, payee, imgPos)
  const calcRemain = () => {
    if (info.expiration === undefined) {
      return
    }

    const tmp = Math.floor(info.expiration - new Date().getTime() / 1000)

    if (tmp > 0) {
      let m: string = (Math.floor(tmp / 60)).toFixed(0)
      let s: string = (tmp % 60).toFixed(0)

      if (m.length === 1) {
        m = `0${m}`
      }

      if (s.length === 1) {
        s = `0${s}`
      }

      setRemain(`${m} : ${s}`)

      if (count % 3 === 0) {
        GetOrder(info.id).then((res) => {
          console.log(res)
    
          if (res.order && res.order.status === 'paid') {
            showCallback(false)
            toast.onShow({
              msg: '支付成功！感谢您的购买，模拟考试券已经放入您的券包。',
              variant: 'success',
            });
            setTimeout(() => {
              navigate(`/users/${username}/practices`, { replace: true });
            }, 3000)
          }
        })
      }

      setCount(count + 1)

      // setTimeout(calcRemain, 1000)
    } else {
      showCallback(false)
    }
  }

  useEffect(() => {
    const timer = setTimeout(calcRemain, 1000)
    return () => {
      clearTimeout(timer)
    }
  })

  return (
    <Modal show={show} onHide={handleCloseOrder}>
      <Modal.Header closeButton>
        <Modal.Title>请{paymentText}： ￥{(info.amount - info.discount).toFixed(2)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{textAlign: "center", color: "orange"}}>{info.discount > 0 ? (<p><span>恭喜您获得随机立减</span><strong>￥{info.discount.toFixed(2)}</strong><span>元!</span></p>) : (<br />)}</div>
        <Card bg={info.payment_method === 'wx' ? 'success': 'primary'}>
          {/* <div style={{ textAlign: "center" }}>
            <div style={qrcodeStyle} />
          </div> */}
          <Card.Img src={info.qrcode} />
          <Card.Body style={{ textAlign: 'center' }}>
            {info.expiration && (<Card.Title>{remain}</Card.Title>)}
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
};

export default memo(Index);
