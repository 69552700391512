import qs from 'qs';
import useSWR from 'swr';

import request from '@/utils/request';
import type * as Type from '@/common/interface';

export const usePracticeList = () => {
  const apiUrl = `/answer/api/v1/practice/options`;
  const { data, error } = useSWR<Type.ListResult, Error>(
    [apiUrl],
    request.instance.get,
  );
  return {
    data,
    isLoading: !data && !error,
    error,
  };
};

export const useQueryOrders = (params) => {
  const apiUrl = `/answer/api/v1/order/page?${qs.stringify(params)}`;
  const { data, error, mutate } = useSWR<Type.ListResult, Error>(
    apiUrl,
    request.instance.get,
  );
  return {
    data,
    isLoading: !data && !error,
    error,
    mutate,
  };
};

export const StartPractice = (code: string) => {
  const apiUrl = `/answer/api/v1/ticket/start-practice`;
  return request.post(apiUrl, {"code": code});
};

export const AddOrder = (param) => {
  const apiUrl = `/answer/api/v1/order`;
  return request.post(apiUrl, param);
};

export const GetOrder = (id: string) => {
  const apiUrl = `/answer/api/v1/order/info?id=${id}`;
  return request.get(apiUrl);
};

export const CancelOrder = (code: string) => {
  const apiUrl = `/answer/api/v1/order/status`;
  return request.put(apiUrl, {"code": code, "status": "cancelled"});
};

export const FreeTicket = (param) => {
  const apiUrl = `/answer/api/v1/ticket/free`;
  return request.post(apiUrl, param);
};