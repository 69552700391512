import React from 'react';
import { Container } from 'react-bootstrap';
// import { Trans } from 'react-i18next';

const Index = () => {
  return (
    <footer className="bg-light py-3">
      <Container>
        <div className="text-center mb-0 fs-14 text-secondary">
          &copy; 2022 SQLEND.COM
          <div>
            <a className="link-secondary" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61070202000230">
              陕公网安备 61070202000230号
            </a>&nbsp;&nbsp;&nbsp;&nbsp;
            <a className="link-secondary" href="https://beian.miit.gov.cn/">
              陕ICP备14000055号-5
            </a>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default React.memo(Index);
