import qs from 'qs';
import useSWR from 'swr';

import request from '@/utils/request';
import type * as Type from '@/common/interface';

export const changeTicketStatus = (params) => {
  return request.put('/answer/admin/api/ticket/status', params);
};

export const useQueryTickets = (params) => {
  const apiUrl = `/answer/admin/api/ticket/page?${qs.stringify(params)}`;
  const { data, error, mutate } = useSWR<Type.ListResult, Error>(
    apiUrl,
    request.instance.get,
  );
  return {
    data,
    isLoading: !data && !error,
    error,
    mutate,
  };
};

export const useGenerateTickets = (practiceCode: string, n: number) => {
  const apiUrl = `/answer/admin/api/ticket/generate`;

  return request.post(apiUrl, { "practice_code": practiceCode, "number": n });
};

export const useBindTicket = (code: string, pwd: string) => {
  const apiUrl = `/answer/api/v1/ticket/bind`;

  return request.post(apiUrl, { "code": code, "password": pwd });
};