import { FC, memo } from 'react';

import dayjs from 'dayjs';

interface Props {
  unixTime: number;
  showTime?: boolean;
}

const Index: FC<Props> = ({ unixTime, showTime = true }) => {
  if (unixTime < 0) {
    return null;
  }

  return (
    <time>
      {dayjs.unix(unixTime).tz().format(`YYYY-MM-DD${showTime ? " HH:mm:ss": ""}`)}
    </time>
  );
};

export default memo(Index);
